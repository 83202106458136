import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useNavigate } from '@reach/router';
import {
    withStyles,
    FormGroup,
    FormControlLabel,
    Checkbox,
    InputAdornment,
} from '@material-ui/core';
import { Person, Lock } from '@material-ui/icons';
import { black } from '@avangard/ui/colors';
import { TextField, Button } from '@avangard/ui/core';

import { routes } from '@config/routes';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { authService } from '@modules/auth/service';
import { LoginMutation } from '@modules/auth/graphql';

import { LoginMutationType, LoginMutationVariables } from '@modules/types/graphql';

type SigninFormProps = {
    className?: string;
};

const StyledTextField = withStyles({
    root: {
        marginBottom: 24,
    },
})(TextField);

const StyledFormGroup = withStyles({
    root: {
        margin: '-12px 0 12px 0',
    },
})(FormGroup);

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
    }

    > h3 {
        color: ${black[80]};
        font-size: 2.8rem;
        font-weight: 500;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledForm = styled(Form)`
    width: 100%;
`;

const personIcon = <Person style={{ fontSize: 16 }} />;
const lockIcon = <Lock style={{ fontSize: 16 }} />;

const SigninForm = (props: SigninFormProps): React.ReactElement => {
    const navigate = useNavigate();

    const [login] = useMutation<LoginMutationType, LoginMutationVariables>(LoginMutation);

    return (
        <Root {...props}>
            <Header>
                <h3>Авторизация</h3>
            </Header>

            <Main>
                <ExtendedFormik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        login: yup.string().required('Введите имя пользователя или email'),
                        password: yup.string().required('Введите пароль'),
                    })}
                    initialValues={{
                        login: '',
                        password: '',
                        rememberMe: true,
                    }}
                    onSubmit={async values => {
                        try {
                            const { data: loginData } = await login({
                                variables: {
                                    username: values.login,
                                    password: values.password,
                                },
                            });

                            if (!loginData?.login) {
                                throw new Error('Некорректные данные');
                            }

                            authService.login();
                            await navigate(routes.regions.path);
                        } catch (e) {
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } =
                            formikProps;

                        return (
                            <StyledForm>
                                <StyledTextField
                                    fullWidth
                                    id='login'
                                    label='Имя пользователя или email'
                                    placeholder='Например, you@mail.ru'
                                    value={values.login}
                                    error={!!errors.login}
                                    helperText={errors.login}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {personIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <StyledTextField
                                    fullWidth
                                    id='password'
                                    type='password'
                                    label='Пароль'
                                    placeholder='Например, 123456'
                                    value={values.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {lockIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <StyledFormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='rememberMe'
                                                checked={values.rememberMe}
                                                onChange={handleChange}
                                            />
                                        }
                                        label='Запомнить меня'
                                    />
                                </StyledFormGroup>

                                <FormStyled.FormRowSubmit>
                                    <Button fullWidth type='submit' loading={isSubmitting}>
                                        Войти
                                    </Button>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </Main>
        </Root>
    );
};

export { SigninForm };
