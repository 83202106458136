import React from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { useLocation, useNavigate } from '@reach/router';
import { ClickAwayListener, MenuList, MenuItem as MuiMenuItem, Hidden } from '@material-ui/core';
import { Group, KeyboardArrowDown, PermIdentity } from '@material-ui/icons';
import { white } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { routes } from '@config/routes';
import { PopperPopup } from '@modules/layout/moleculas';
import { authService } from '@modules/auth/service';

type HeaderUserInfoProps = {
    fullName: string;
};

const StyledPopperPopup = styled(PopperPopup)`
    margin-left: -14px;
`;

const AwayListenerRoot = styled.div``;

const Root = styled.div`
    display: flex;
    align-items: center;
    min-width: 125px;
    height: 64px;
    padding: 0 24px;
    text-align: right;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: ${white[90]};
    }

    ${breakpoints.down('xs')} {
        min-width: auto;
        height: 44px;
        padding: 0 14px;
    }
`;

const Username = styled.div`
    font-size: 1.4rem;
`;

const Icon = styled.div`
    display: flex;
    width: 18px;
    height: 18px;
    margin-left: 8px;

    ${breakpoints.down('xs')} {
        width: 24px;
        height: 24px;
        margin-left: 0;
    }
`;

const MenuItem = styled(MuiMenuItem)`
    > span:first-child {
        display: flex;
        width: 18px;
        height: 18px;
    }

    > p {
        width: calc(100% - 32px);
        margin-left: 8px;
    }
`;

const arrowDownIcon = <KeyboardArrowDown style={{ fontSize: 18 }} />;
const permIdentityIcon = <PermIdentity style={{ fontSize: 24 }} />;
const groupIcon = <Group style={{ fontSize: 18 }} />;

const HeaderUserInfo = (props: HeaderUserInfoProps): React.ReactElement => {
    const { fullName } = props;

    const apolloClient = useApolloClient();
    const location = useLocation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopperClose = (): void => setAnchorEl(null);

    const handleClickPopperToggle = (event: React.SyntheticEvent<HTMLDivElement>): void =>
        setAnchorEl(anchorEl ? null : event.currentTarget);

    const handleClickLogout = async (): Promise<void> => {
        handlePopperClose();

        try {
            authService.logout();
        } catch (e) {
            console.log(e);
        } finally {
            await apolloClient.clearStore();
            await navigate(routes.login.path, { replace: true });
        }
    };

    React.useEffect(() => {
        if (anchorEl) {
            handlePopperClose();
        }
    }, [location.pathname]);

    return (
        <ClickAwayListener onClickAway={handlePopperClose}>
            <AwayListenerRoot>
                <Root onClick={handleClickPopperToggle}>
                    <Hidden xsDown>
                        <Username>{fullName}</Username>
                        <Icon>{arrowDownIcon}</Icon>
                    </Hidden>

                    <Hidden smUp>
                        <Icon>{permIdentityIcon}</Icon>
                    </Hidden>
                </Root>

                <StyledPopperPopup placement='bottom-end' open={!!anchorEl} anchorEl={anchorEl}>
                    <MenuList>
                        <MenuItem onClick={handleClickLogout}>
                            <span>{groupIcon}</span>
                            <p>Выйти</p>
                        </MenuItem>
                    </MenuList>
                </StyledPopperPopup>
            </AwayListenerRoot>
        </ClickAwayListener>
    );
};

export { HeaderUserInfo };
