import React from 'react';

import { useCurrentUser } from '@modules/auth/hooks';

import type { Route } from '@config/routes';

const PublicRoute = (props: Route): React.ReactElement | null => {
    const { Component, LayoutComponent, ...otherProps } = props;

    return (
        <LayoutComponent pageTitle={otherProps.title} withBackIcon={otherProps.withBackIcon}>
            <Component {...otherProps} />
        </LayoutComponent>
    );
};

export { PublicRoute };
