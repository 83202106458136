import React from 'react';
import styled from 'styled-components';
import { Place, FormatListBulleted } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { Button } from '@avangard/ui/core';

import { Loader } from '@modules/layout/moleculas';
import { distributeToAlphabetGroups } from '@modules/regions/helpers';
import { RegionEntity } from '@modules/regions/entities';
import { useRegions } from '@modules/regions/hooks';
import {
    RegionGroupStyled,
    RegionGroupClickable,
    RegionInfoModal,
} from '@modules/regions/organisms';
import { useMapDimensions } from '@modules/map/hooks';
import { MapSvg } from '@modules/map/moleculas';

type View = 'list' | 'map';

const ToggleButton = withStyles({
    root: {
        height: '3.2rem',
    },
    iconSizeMedium: {
        '& > *:first-child': {
            fontSize: '2rem',
        },
    },
})(Button);

const Root = styled.div`
    width: 100vw;
    background-color: #303030;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: absolute;
    top: 3rem;
    padding-left: 6rem;
    width: 100%;

    > h1 {
        display: inline-flex;
        margin-right: 2.4rem;
        color: ${p => p.theme.palette.common.white};
        font-size: 4rem;
        font-weight: 400;
    }

    > button {
        top: 4px;
    }
`;

const MapWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const RegionsGroups = styled(RegionGroupStyled.Groups)`
    padding-top: 12rem;
    padding-left: 6rem;
`;

const TvInteractivePage = (): React.ReactElement => {
    const { regions, loading: regionsLoading } = useRegions({ observe: false });

    const { mapWrapperRef, mapDimensions, showMap } = useMapDimensions();

    const [view, setView] = React.useState<View>('list');

    const handleToggleView = (): void => setView(view => (view === 'map' ? 'list' : 'map'));

    const [regionIdForModal, setRegionIdForModal] = React.useState<string | null>(null);

    // complexRegionId = id#slug
    const handleSetRegionIdForModal = (complexRegionId: string): void =>
        setRegionIdForModal(complexRegionId);

    const handleResetRegionForModal = (): void => setRegionIdForModal(null);

    const isMapView = view === 'map';
    const hasRegionForModalData = regionIdForModal !== null;

    const alphabetListRegions = React.useMemo(() => {
        const mosSlug = 'RU-MOS';
        const lenSlug = 'RU-LEN';

        const mosRegion = regions.find(region => region.slug === mosSlug);
        const lenRegion = regions.find(region => region.slug === lenSlug);

        const updatedRegions = regions.map(region => {
            if (region.slug === mosSlug) {
                return new RegionEntity({ ...region, title: 'Москва' });
            }

            if (region.slug === lenSlug) {
                return new RegionEntity({ ...region, title: 'Санкт-Петербург' });
            }

            return region;
        });

        const result = [
            ...updatedRegions,
            new RegionEntity({
                ...mosRegion,
                slug: mosSlug + '-AREA',
                title: 'Московская область',
            }),
            new RegionEntity({
                ...lenRegion,
                slug: lenSlug + '-AREA',
                title: 'Ленинградская область',
            }),
        ];

        return distributeToAlphabetGroups(result);
    }, [regions]);

    if (regionsLoading) {
        return (
            <Root>
                <Loader />
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <h1>География центров</h1>

                <ToggleButton
                    startIcon={isMapView ? <FormatListBulleted /> : <Place />}
                    onClick={handleToggleView}
                >
                    {isMapView ? 'Показать списком' : 'Показать на карте'}
                </ToggleButton>
            </Header>

            {isMapView ? (
                <MapWrapper ref={mapWrapperRef}>
                    {showMap ? (
                        <MapSvg
                            withoutInteractive
                            regions={regions}
                            width={mapDimensions.width}
                            height={mapDimensions.height}
                            transformDimension={mapDimensions.transformDimension}
                            onClick={handleSetRegionIdForModal}
                        />
                    ) : (
                        <Loader />
                    )}
                </MapWrapper>
            ) : (
                <RegionsGroups>
                    {alphabetListRegions.map(alphabetRegion => (
                        <RegionGroupClickable
                            withoutInteractive
                            key={alphabetRegion.letter}
                            alphabetGroup={alphabetRegion}
                            onClick={handleSetRegionIdForModal}
                        />
                    ))}
                </RegionsGroups>
            )}

            <RegionInfoModal
                complexRegionId={regionIdForModal}
                open={hasRegionForModalData}
                onClose={handleResetRegionForModal}
            />
        </Root>
    );
};

export { TvInteractivePage };
