import React from 'react';
import styled from 'styled-components';
import { black, white } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { useBaseLayout } from '@modules/layout/templates';
import { useCurrentUser } from '@modules/auth/hooks';
import { HeaderUserInfo } from './header-user-info';

const Root = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 64px;
    height: 64px;
    padding-left: 24px;
    position: relative;
    background-color: ${white[100]};
    border-bottom: 1px solid ${white[80]};
    z-index: 2;

    ${breakpoints.down('xs')} {
        position: sticky;
        left: 0;
        top: 0;
        min-height: 44px;
        height: 44px;
        padding-left: 14px;
    }
`;

const HeaderBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px;
`;

const PageTitle = styled.div`
    font-size: 2rem;
    font-weight: 700;
    color: ${black[80]};

    ${breakpoints.down('sm')} {
        font-size: 1.5rem;
        margin-left: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 70px);
        overflow: hidden;
    }
`;

const HeaderBlockUser = styled(HeaderBlock)`
    margin-left: auto;
    padding: 0;
`;

const Header = (): React.ReactElement => {
    const { pageTitle } = useBaseLayout();

    const currentUser = useCurrentUser();

    return (
        <Root>
            <PageTitle>{pageTitle}</PageTitle>

            {currentUser ? (
                <HeaderBlockUser>
                    <HeaderUserInfo fullName={currentUser.fullName} />
                </HeaderBlockUser>
            ) : null}
        </Root>
    );
};

export { Header };
