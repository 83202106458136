import React from 'react';

const calculateMapDimensions = (width: number, height: number) => {
    const transformDimension = Math.round((width * 0.8) / 100) / 10 - 0.05;

    const dimensions = {
        width,
        height,
        transformDimension,
    };

    return dimensions;
};

type MapDimensions = {
    width: number;
    height: number;
    transformDimension: number;
};

type UseMapDimensionsResult = {
    mapWrapperRef: (node: HTMLDivElement | null) => void;
    mapDimensions: MapDimensions;
    showMap: boolean;
};

const useMapDimensions = (): UseMapDimensionsResult => {
    const ref = React.useRef<HTMLDivElement | null>(null);

    const [mapDimensions, setMapDimensions] = React.useState<any>({
        width: 0,
        height: 0,
        transformDimension: 1.2,
    });

    const mapWrapperRef = React.useCallback((node: HTMLDivElement | null) => {
        if (node) {
            setMapDimensions(calculateMapDimensions(node.clientWidth, node.clientHeight));
        }

        ref.current = node;
    }, []);

    React.useEffect(() => {
        const handleResize = (): void => {
            const node = ref.current;

            if (node) {
                setMapDimensions(calculateMapDimensions(node.clientWidth, node.clientHeight));
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const showMap = mapDimensions.width !== 0 && mapDimensions.height !== 0;

    const result = {
        mapWrapperRef,
        mapDimensions,
        showMap,
    };

    return result;
};

export { useMapDimensions };
