import React from 'react';
import styled, { css } from 'styled-components';
import { noop as _noop } from 'lodash';

import { AlphabetGroup } from '@modules/regions/helpers';
import { ruRegions } from '@modules/map/constants';
import { RegionGroupStyled } from './styled';

type RegionGroupProps = {
    alphabetGroup: AlphabetGroup;
    onClick: (regionId: string) => void;
    withoutInteractive?: boolean;
    className?: string;
};

const Label = styled(RegionGroupStyled.Label)`
    color: #9e9e9e;
`;

const List = styled(RegionGroupStyled.List)`
    > *:not(:last-child) {
        margin-bottom: 12px;
    }
`;

const Region = styled.div<{ withoutInteractive?: boolean; filled?: boolean }>`
    color: ${p => (p.filled ? '#fff' : '#9e9e9e')};
    font-size: 1.6rem;
    transition: color 0.2s;
    cursor: pointer;

    ${p =>
        !p.withoutInteractive &&
        css`
            &:hover {
                color: ${p => p.theme.palette.common.white};
            }
        `}
`;

const RegionGroupClickable = (props: RegionGroupProps): React.ReactElement => {
    const { alphabetGroup, withoutInteractive, onClick, ...otherProps } = props;

    const findRegion = React.useCallback(
        (regionSlug: string) => ruRegions.find(ruRegion => regionSlug === ruRegion.id),
        [],
    );

    const handleClick = (regionId: string) => (): void => onClick(regionId);

    return (
        <RegionGroupStyled.Group {...otherProps}>
            <Label>{alphabetGroup.letter}</Label>

            <List>
                {alphabetGroup.regions.map(region => {
                    const foundRegion = findRegion(region.slug);
                    const complexRegionId = region.id + '#' + region.slug;

                    const filled = withoutInteractive
                        ? !!foundRegion?.enabledWithoutInteractive
                        : region.enabled;

                    const canInteract = withoutInteractive
                        ? !!foundRegion?.enabledWithoutInteractive
                        : true;

                    return (
                        <Region
                            withoutInteractive
                            key={complexRegionId}
                            filled={filled}
                            onClick={canInteract ? handleClick(complexRegionId) : _noop}
                        >
                            {region.title}
                        </Region>
                    );
                })}
            </List>
        </RegionGroupStyled.Group>
    );
};

export { RegionGroupClickable };
