import type { ApolloError } from '@apollo/client';
import type { FormikValues, FormikErrors } from 'formik';

type SetServerErrorsOptions<T> = {
    error: ApolloError;
    setFieldsFn?: (errors: FormikErrors<T>) => void;
    setGlobalFn?: (error: string) => void;
    dictionary?: Record<string, string>;
};

const setServerErrors = <T = FormikValues>(options: SetServerErrorsOptions<T>): void => {
    const { error, setFieldsFn, setGlobalFn, dictionary } = options;

    const networkError = error.networkError;

    if (networkError && 'result' in networkError) {
        const errors = networkError.result.errors as Record<string, any>[];

        const [maybeInternalError] = networkError.result.errors;

        if (
            setGlobalFn &&
            maybeInternalError.extensions?.status === 401 &&
            typeof maybeInternalError.message !== 'undefined'
        ) {
            setGlobalFn(maybeInternalError.message);
            return;
        }

        const displayErrors = errors.reduce((carry, error) => {
            if (!error.path) {
                return carry;
            }

            const [errorPath] = error.path;
            const errorKey = dictionary?.[errorPath] ?? errorPath;

            return {
                ...carry,
                [errorKey]: error.message,
            };
        }, {});

        const issetDisplayErrors = Object.keys(displayErrors).length !== 0;

        if (setFieldsFn && issetDisplayErrors) {
            setFieldsFn(displayErrors as FormikErrors<T>);
            return;
        }

        if (setGlobalFn && typeof maybeInternalError.message !== 'undefined') {
            setGlobalFn(maybeInternalError.message);
        }

        return;
    }

    if (setGlobalFn && error.message) {
        setGlobalFn(error.message);
    }

    return;
};

export { setServerErrors };
