import React from 'react';
import styled from 'styled-components';

import { CommonHelper } from '@helpers';
import { Loader } from '@modules/layout/moleculas';
import { useRegions } from '@modules/regions/hooks';
import { useMapDimensions } from '@modules/map/hooks';
import { MapInfoBlock, MapSvg } from '@modules/map/moleculas';

const Root = styled.div`
    width: 100vw;
    background-color: #303030;
`;

const RegionsCountText = styled.div`
    color: ${p => p.theme.palette.common.white};
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;

    > p {
        font-weight: 400;
        font-size: 8rem;
    }
`;

const MapWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const TvPage = (): React.ReactElement => {
    const { regions, enabledRegions, loading: regionsLoading } = useRegions();

    const { mapWrapperRef, mapDimensions, showMap } = useMapDimensions();

    if (regionsLoading) {
        return (
            <Root>
                <Loader />
            </Root>
        );
    }

    return (
        <Root>
            <MapInfoBlock>
                <RegionsCountText>
                    С нами <p>{enabledRegions.length}</p>
                    {CommonHelper.createCountFormatter(
                        enabledRegions.length,
                        ['регион', 'региона', 'регионов'],
                        false,
                    )}
                </RegionsCountText>
            </MapInfoBlock>

            <MapWrapper ref={mapWrapperRef}>
                {showMap ? (
                    <MapSvg
                        regions={regions}
                        width={mapDimensions.width}
                        height={mapDimensions.height}
                        transformDimension={mapDimensions.transformDimension}
                    />
                ) : (
                    <Loader />
                )}
            </MapWrapper>
        </Root>
    );
};

export { TvPage };
