import Cookie from 'js-cookie';

import { SystemHelper } from '@helpers';

const cookieOptions = {
    secure: SystemHelper.isProd(),
};

class AuthService {
    authorizedKey = 'authorized';

    login() {
        Cookie.set(this.authorizedKey, 'true', cookieOptions);
    }

    authorized() {
        return Cookie.get(this.authorizedKey) === 'true';
    }

    logout() {
        Cookie.remove(this.authorizedKey, cookieOptions);
    }
}

const authService = new AuthService();

export { authService };
