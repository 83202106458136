import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { Modal } from '@avangard/ui/core';

import { Loader } from '@modules/layout/moleculas';
import { useRegion } from '@modules/regions/hooks';

import type { ModalProps } from '@avangard/ui/core';

type RegionInfoModalProps = Omit<ModalProps, 'title' | 'children'> & {
    complexRegionId: string | null;
};

const useModalStyles = makeStyles({
    paper: {
        maxWidth: '57rem',
    },

    container: {
        width: '100%',
    },

    header: {
        height: '6.4rem',
        padding: '0 1.6rem',

        '&> div:first-child': {
            color: 'rgba(0,0,0,.87)',
            fontSize: '2rem',
            fontWeight: 600,
        },
    },

    body: {
        padding: 0,
        color: 'rgba(0,0,0,.87)',
    },
}) as any;

const Image = styled.div`
    width: 100%;
    height: 27rem;

    > img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const StartDate = styled.div`
    padding: 1.6rem 2.4rem 0 2.4rem;

    > p {
        font-size: 1.6rem;
    }

    > h3 {
        color: #757645;
        margin-top: 8px;
        font-size: 3.4rem;
        font-weight: 600;
    }
`;

const Body = styled.div`
    width: 100%;
    padding: 1.6rem 2.4rem;
    font-size: 1.6rem;
    line-height: 2.2rem;

    > p {
        width: 100%;
    }
`;

const RegionInfoModal = (props: RegionInfoModalProps): React.ReactElement => {
    const { complexRegionId, onClose, ...otherProps } = props;

    const modalClasses = useModalStyles();

    const regionId = complexRegionId ? complexRegionId.split('#')[0] : null;

    const { region, loading: regionLoading } = useRegion({
        skip: !regionId,
        variables: { id: regionId as string },
    });

    return (
        <Modal classes={modalClasses} title={region.title} onClose={onClose} {...otherProps}>
            {regionLoading ? (
                <Loader />
            ) : (
                <>
                    <Image>
                        <img src={region.image} alt='' />
                    </Image>

                    <StartDate>
                        <h3>{region.getDateLabel()}</h3>
                    </StartDate>

                    <Body dangerouslySetInnerHTML={{ __html: region.description }} />
                </>
            )}
        </Modal>
    );
};

export { RegionInfoModal };
