import Cookie from 'js-cookie';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import { GRAPHQL_URL, GRAPHQL_WS_URL } from '@config/environment';
import { SystemHelper } from '@helpers';

import type {
    ApolloClientOptions as BaseApolloClientOptions,
    NormalizedCacheObject,
} from '@apollo/client';

type ApolloClientOptions = Omit<BaseApolloClientOptions<NormalizedCacheObject>, 'cache'>;

const isDev = SystemHelper.isDev();

const createApolloClient = (options: ApolloClientOptions = {}) => {
    const httpLink = new HttpLink({ uri: GRAPHQL_URL });

    const wsLink = new WebSocketLink({
        uri: GRAPHQL_WS_URL,
        options: {
            reconnect: true,
        },
    });

    const splitLink = split(
        operation => {
            const definition = getMainDefinition(operation.query);

            return (
                definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    const apolloClient = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache(),
        connectToDevTools: isDev,
        ...options,
    });

    return apolloClient;
};

export { createApolloClient };
