import React from 'react';
import { Redirect } from '@reach/router';
import { useCurrentUser } from '@modules/auth/hooks';

import { routes } from '@config/routes';

const IndexPage = (): React.ReactElement => {
    const currentUser = useCurrentUser();

    if (!currentUser) {
        return <div>У вас нет доступа к разделу</div>;
    }

    return <Redirect noThrow to={routes.regions.path} />;
};

export { IndexPage };
