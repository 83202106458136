import React from 'react';
import styled from 'styled-components';
import { withStyles, FormControlLabel, Switch } from '@material-ui/core';
import { red } from '@avangard/ui/colors';

import { RegionGroupStyled } from './styled';

import type { AlphabetGroup } from '@modules/regions/helpers';
import type { RegionEntity } from '@modules/regions/entities';

type RegionGroupProps = {
    alphabetGroup: AlphabetGroup;
    onToggle: (region: RegionEntity) => Promise<void>;
    className?: string;
};

const RedSwitch = withStyles({
    root: {
        height: 34,
    },
    switchBase: {
        top: 1,
        left: 2,
        padding: 8,

        '&$checked': {
            color: red[100],
        },
        '&$checked + $track': {
            backgroundColor: red[100],
        },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    checked: {},
    track: {},
})(Switch);

const RegionGroupToggle = (props: RegionGroupProps): React.ReactElement => {
    const { alphabetGroup, onToggle, ...otherProps } = props;

    const handleChange = React.useCallback(
        (region: RegionEntity) => async () => onToggle(region),
        [],
    );

    return (
        <RegionGroupStyled.Group {...otherProps}>
            <RegionGroupStyled.Label>{alphabetGroup.letter}</RegionGroupStyled.Label>
            <RegionGroupStyled.List>
                {alphabetGroup.regions.map(region => (
                    <FormControlLabel
                        key={region.id}
                        label={region.title}
                        control={
                            <RedSwitch
                                name={`region-${region.id}`}
                                checked={region.enabled}
                                onChange={handleChange(region)}
                            />
                        }
                    />
                ))}
            </RegionGroupStyled.List>
        </RegionGroupStyled.Group>
    );
};

export { RegionGroupToggle };
