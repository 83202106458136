import { breakpoints } from '@avangard/ui/utils';

const globalStyles = `
    html {
        @media (min-width: 1600px) {
            font-size: 14px;
        }

        @media (min-width: 1920px) {
            font-size: 12px;
        }

        @media (min-width: 2400px) {
            font-size: 22px;
        }

        @media (min-width: 3000px) {
            font-size: 28px;
        }

        @media (min-width: 5000px) {
            font-size: 40px;
        }
    }

    html, body { height: 100%; }
`;

export { globalStyles };
