import { IndexPage } from '@pages/index';
import { LoginPage } from '@pages/auth/login';
import { RegionsPage } from '@pages/regions';
import { TvPage } from '@pages/tv';
import { TvInteractivePage } from '@pages/tv/interactive';
import { EmptyLayout, BaseLayout } from '@modules/layout/templates';

import type { RouteComponentProps, RedirectProps } from '@reach/router';

export enum RouteMenuLocationEnum {
    sidebar = 'sidebar',
}

export type Route = {
    name: string;
    title: string;
    path: string;
    private: boolean;
    Component: React.FunctionComponent<any>;
    LayoutComponent: React.FunctionComponent<any>;
    menuTitle?: string;
    withBackIcon?: boolean;
    default?: boolean;
    menuLocations?: RouteMenuLocationEnum[];
    menuIcon?: React.FunctionComponent<any>;
    children?: React.ReactNode;
};

export type ConfigRoute = Record<string, Route>;

export type RedirectRoute = RouteComponentProps<RedirectProps<{}>> & {
    name: string;
};

const routes: ConfigRoute = {
    index: {
        name: 'index',
        title: 'Index',
        path: '/',
        private: true,
        Component: IndexPage,
        LayoutComponent: EmptyLayout,
    },

    login: {
        name: 'auth.login',
        title: 'Login',
        path: '/auth/login',
        private: false,
        Component: LoginPage,
        LayoutComponent: EmptyLayout,
    },

    regions: {
        name: 'regions.index',
        title: 'География центров',
        path: '/regions',
        private: true,
        Component: RegionsPage,
        LayoutComponent: BaseLayout,
    },

    tv: {
        name: 'tv.index',
        title: 'Статичная карта',
        path: '/tv',
        private: false,
        Component: TvPage,
        LayoutComponent: EmptyLayout,
    },

    tvInteractive: {
        name: 'tv.interactive.index',
        title: 'Интерактивная карта',
        path: '/tv-interactive',
        private: false,
        Component: TvInteractivePage,
        LayoutComponent: EmptyLayout,
    },

    error: {
        name: 'error',
        title: 'Error Page',
        path: '/error',
        private: false,
        Component: IndexPage, // TODO: Create Error page
        LayoutComponent: EmptyLayout,
    },
};

const redirects: RedirectRoute[] = [
    {
        name: 'default',
        from: '/',
        to: routes.login.path,
        default: true,
    },
];

export { routes, redirects };
