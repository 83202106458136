import { gql } from '@apollo/client';

import { RegionFragment } from '../fragments';

export const GetRegionQuery = gql`
    query GetRegion($id: ID!) {
        getRegion(id: $id) {
            ...Region
        }
    }
    ${RegionFragment}
`;
