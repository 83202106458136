import React from 'react';
import styled from 'styled-components';
import { Redirect } from '@reach/router';
import { Hidden } from '@material-ui/core';
import { white } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { routes } from '@config/routes';
import { Logo } from '@modules/layout/icons';
import { useCurrentUser } from '@modules/auth/hooks';
import { SigninForm } from '@modules/auth/organisms';

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${breakpoints.down('xs')} {
        flex-direction: column;
        justify-content: initial;
    }
`;

const Screen = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const WelcomeScreen = styled(Screen)`
    flex: 0 0 auto;
    min-width: 266px;
    width: 35%;
    background-image: url('/images/main-background.jpg');

    > h1 {
        max-width: 230px;
        margin-top: 2em;
        color: ${white[100]};
        font-size: 1.6rem;
        font-weight: 400;
        text-align: center;
        line-height: 22px;

        ${breakpoints.down('xs')} {
            margin: 0;
        }
    }

    ${breakpoints.down('xs')} {
        width: 100%;
        height: 80px;
    }
`;

const WelcomeLogo = styled(Logo)`
    max-width: 170px;
    max-height: 170px;

    ${breakpoints.down('xs')} {
        max-height: 100%;
        max-width: none;
        padding: 10px 0;
    }
`;

const SigninScreen = styled(Screen)`
    width: 100%;
    flex: 1 1 auto;

    ${breakpoints.down('xs')} {
        height: 100%;
        padding: 10px;
    }
`;

const LoginPage = (): React.ReactElement => {
    const currentUser = useCurrentUser();

    if (currentUser) {
        return <Redirect noThrow to={routes.index.path} />;
    }

    return (
        <Root>
            <WelcomeScreen>
                <WelcomeLogo />

                <Hidden xsDown>
                    <h1>Учебно-методический центр военно-патриотического воспитания молодёжи</h1>
                </Hidden>
            </WelcomeScreen>

            <SigninScreen>
                <SigninForm />
            </SigninScreen>
        </Root>
    );
};

export { LoginPage };
