import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Region } from '@modules/types/graphql';

export type Entity = Region | null;

@DecribeEntity('RegionEntity')
class RegionEntity extends BaseEntity {
    id: string;
    slug: string;
    title: string;
    description: string;
    image: string;
    startDate: string;
    enabled: boolean;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.slug = entity?.slug ?? '';
        this.title = entity?.title ?? '';
        this.description = entity?.description ?? '';
        this.image = this.createImagePath();
        this.startDate = entity?.startDate ?? '';
        this.enabled = entity?.enabled ?? false;
    }

    private createImagePath() {
        const slugToLower = this.slug.toLocaleLowerCase().split('-')[1];
        const path = `/images/regions/${slugToLower}.png`;

        return path;
    }

    getDateLabel() {
        if (!this.startDate) {
            return '-';
        }

        const fullDate = [...this.startDate.split('-')].reverse().join('.');

        return fullDate;
    }
}

export { RegionEntity };
