import React from 'react';
import styled, { css } from 'styled-components';
import { noop as _noop } from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import { red } from '@avangard/ui/colors';

import { ruRegions } from '@modules/map/constants';

import type { RegionEntity } from '@modules/regions/entities';

type MapSvgProps = {
    regions: RegionEntity[];
    width: number;
    height: number;
    transformDimension: number;
    onClick?: (regionId: string) => void;
    withoutInteractive?: boolean;
    className?: string;
};

const Root = styled.svg``;

const Path = styled.path<{ withoutInteractive?: boolean; filled?: boolean }>`
    fill: ${p => (!!p.filled ? red[100] : '#bdbdbd')};
    cursor: pointer;
    transition: fill 0.2s;

    ${p =>
        !p.withoutInteractive &&
        css`
            &:hover {
                fill: ${red[100]};
            }
        `}
`;

const LARGE_MEDIA_Y_OFFSET_PERCENT = 7;
const MEDIA_Y_OFFSET_PERCENT = 10;

const MapSvg = (props: MapSvgProps): React.ReactElement => {
    const { regions, transformDimension, withoutInteractive, onClick, ...otherProps } = props;

    const isLargeMedia = useMediaQuery('(min-width: 1919px)');

    const findRegion = (regionSlug: string) => regions.find(region => regionSlug === region.slug);

    const handleClick = (region: RegionEntity | null) => (): void => {
        if (onClick && region) {
            onClick(region.id);
        }
    };

    const transform = `scale(${transformDimension}) translate(5%, ${
        isLargeMedia ? LARGE_MEDIA_Y_OFFSET_PERCENT : MEDIA_Y_OFFSET_PERCENT
    }%)`;

    return (
        <Root xmlns='http://www.w3.org/2000/svg' {...otherProps}>
            <g fill='none' stroke='#888' strokeWidth='0.5' style={{ transform }}>
                {ruRegions.map(region => {
                    if (!region.path) {
                        return null;
                    }

                    const foundRegion = findRegion(region.id) ?? null;

                    const filled = withoutInteractive
                        ? !!region.enabledWithoutInteractive
                        : foundRegion?.enabled;

                    const canInteract = withoutInteractive
                        ? !!region.enabledWithoutInteractive
                        : true;

                    return (
                        <Path
                            withoutInteractive
                            key={region.id}
                            id={region.id}
                            d={region.path}
                            filled={filled}
                            onClick={canInteract ? handleClick(foundRegion) : _noop}
                        />
                    );
                })}
            </g>
        </Root>
    );
};

export { MapSvg };
