import React from 'react';
import { Redirect } from '@reach/router';

import { routes } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';

import type { Route } from '@config/routes';

const PrivateRoute = (props: Route): React.ReactElement | null => {
    const { Component, LayoutComponent, ...otherProps } = props;

    const currentUser = useCurrentUser();

    if (!currentUser) {
        return <Redirect noThrow to={routes.login.path} />;
    }

    return (
        <LayoutComponent pageTitle={otherProps.title} withBackIcon={otherProps.withBackIcon}>
            <Component {...otherProps} />
        </LayoutComponent>
    );
};

export { PrivateRoute };
