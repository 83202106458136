import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

@DecribeEntity('UserEntity')
class UserEntity extends BaseEntity {
    fullName: string;

    constructor() {
        super({});

        this.fullName = 'Admin';
    }
}

export { UserEntity };
