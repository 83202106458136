import { EntityList } from '@lib/entity';
import { RegionEntity } from './region-entity';

import type { Entity } from './region-entity';

class RegionEntityList extends EntityList<RegionEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RegionEntity, items, count);
    }

    getEnabledRegions() {
        const result = this.entities.filter(entity => entity.enabled === true);

        return result;
    }
}

export { RegionEntityList };
