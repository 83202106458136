import { gql } from '@apollo/client';

import { RegionFragment } from '../fragments';

export const UpdateRegionMutation = gql`
    mutation UpdateRegion($id: ID!, $item: UpdateRegionItem!) {
        updateRegion(id: $id, item: $item) {
            ...Region
        }
    }
    ${RegionFragment}
`;
