import { gql } from '@apollo/client';

export const RegionFragment = gql`
    fragment Region on Region {
        id
        slug
        title
        description
        startDate
        enabled
    }
`;
