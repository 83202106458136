import React from 'react';

import type { DialogSureBackProps } from '@modules/layout/moleculas';

type SureBackProps = DialogSureBackProps | null;

type OnRedirect = (() => () => void) | null;

type BaseLayoutContextValues = {
    pageTitle: string;
    sureBackProps: SureBackProps;
    onRedirect: OnRedirect;
    setPageTitle: (state: string | ((state: string) => string)) => void;
    setSureBackProps: (state: SureBackProps | ((state: SureBackProps) => SureBackProps)) => void;
    setRedirect: (state: OnRedirect | ((state: OnRedirect) => OnRedirect)) => void;
};

type BaseLayoutProviderProps = {
    pageTitle: string;
    children: React.ReactNode;
};

const BaseLayoutContext = React.createContext<BaseLayoutContextValues | undefined>(undefined);

const BaseLayoutProvider = (props: BaseLayoutProviderProps): React.ReactElement => {
    const { pageTitle: pageTitleProps, children } = props;

    const [pageTitle, setPageTitle] = React.useState<BaseLayoutContextValues['pageTitle']>(
        pageTitleProps,
    );

    const [onRedirect, setRedirect] = React.useState<BaseLayoutContextValues['onRedirect']>(null);

    const [sureBackProps, setSureBackProps] = React.useState<
        BaseLayoutContextValues['sureBackProps']
    >(null);

    React.useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    return (
        <BaseLayoutContext.Provider
            value={{
                pageTitle,
                sureBackProps,
                onRedirect,
                setPageTitle,
                setSureBackProps,
                setRedirect,
            }}
        >
            {children}
        </BaseLayoutContext.Provider>
    );
};

const useBaseLayout = (): BaseLayoutContextValues => {
    const context = React.useContext(BaseLayoutContext);

    if (context === undefined) {
        throw new Error('base layout context value are undefined');
    }

    return context;
};

export { BaseLayoutContext, BaseLayoutProvider, useBaseLayout };
