import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { RegionEntity } from '@modules/regions/entities';
import { GetRegionQuery } from '@modules/regions/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetRegionQueryType } from '@modules/types/graphql';

const useRegion = (options: QueryHookOptions<GetRegionQueryType> = {}) => {
    const queryResult = useQuery<GetRegionQueryType>(GetRegionQuery, options);

    const region = useEntity(
        () => new RegionEntity(queryResult.data?.getRegion ?? null),
        [queryResult.data],
    );

    const result = {
        region,
        ...queryResult,
    };

    return result;
};

export { useRegion };
