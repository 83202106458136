import { gql } from '@apollo/client';

import { RegionFragment } from '../fragments';

export const GetRegionsQuery = gql`
    query GetRegions {
        getRegions {
            ...Region
        }
    }
    ${RegionFragment}
`;
