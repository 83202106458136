import type { RegionEntity } from '@modules/regions/entities';

export type AlphabetGroup = {
    letter: string;
    regions: RegionEntity[];
};

const distributeToAlphabetGroups = (regions: RegionEntity[]): AlphabetGroup[] => {
    const sortedRegions = regions.sort((a, b) => (a.title > b.title ? -1 : 1));

    const letters = sortedRegions.reduce<AlphabetGroup[]>((carry, region) => {
        const firstLetter = region.title[0];
        const foundIdx = carry.findIndex(item => item.letter === firstLetter);

        if (foundIdx === -1) {
            carry = [{ letter: firstLetter, regions: [region] }, ...carry];
        } else {
            const found = carry[foundIdx];

            carry[foundIdx] = {
                ...found,
                regions: [region, ...found.regions],
            };
        }

        return carry;
    }, []);

    return letters;
};

export { distributeToAlphabetGroups };
