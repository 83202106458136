import { useEntity } from '@lib/entity';
import { UserEntity } from '@modules/auth/entities';
import { authService } from '@modules/auth/service';

const useCurrentUser = (): UserEntity | null => {
    const isAuthorized = authService.authorized();

    if (!isAuthorized) {
        return null;
    }

    const currentUser = useEntity(() => new UserEntity(), [isAuthorized]);

    return currentUser;
};

export { useCurrentUser };
