import { gql } from '@apollo/client';

import { RegionFragment } from '../fragments';

export const RegionUpdatedSubscription = gql`
    subscription RegionUpdated {
        regionUpdated {
            ...Region
        }
    }
    ${RegionFragment}
`;
