import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { useRegions } from '@modules/regions/hooks';
import { RegionGroupStyled, RegionGroupToggle } from '@modules/regions/organisms';
import { UpdateRegionMutation } from '@modules/regions/graphql';

import type { RegionEntity } from '@modules/regions/entities';
import type {
    UpdateRegionMutationType,
    UpdateRegionMutationVariables,
} from '@modules/types/graphql';

const Root = styled.div`
    width: 100%;
    max-height: 100vh;
`;

const NotFoundText = styled.div`
    font-size: 1.6rem;
`;

const RegionsPage = (): React.ReactElement => {
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { alphabetRegions, loading: regionsLoading } = useRegions();

    const [updateRegion] =
        useMutation<UpdateRegionMutationType, UpdateRegionMutationVariables>(UpdateRegionMutation);

    const handleToggleRegion = async (region: RegionEntity): Promise<void> => {
        try {
            const { data: updateRegionData } = await updateRegion({
                variables: {
                    id: region.id,
                    item: { enabled: !region.enabled },
                },
            });

            if (!updateRegionData?.updateRegion?.id) {
                throw new Error('При переключении состояния региона произошла ошибка');
            }

            enqueueSuccess('Состояние региона успешно изменено');
        } catch (e) {
            enqueueError(typeof e === 'string' ? e : JSON.stringify(e));
        }
    };

    if (regionsLoading) {
        return (
            <Root>
                <Loader />
            </Root>
        );
    }

    if (alphabetRegions.length === 0) {
        return (
            <Root>
                <NotFoundText>Нет ни одного региона</NotFoundText>
            </Root>
        );
    }

    return (
        <Root>
            <RegionGroupStyled.Groups>
                {alphabetRegions.map(alphabetRegion => (
                    <RegionGroupToggle
                        key={alphabetRegion.letter}
                        alphabetGroup={alphabetRegion}
                        onToggle={handleToggleRegion}
                    />
                ))}
            </RegionGroupStyled.Groups>
        </Root>
    );
};

export { RegionsPage };
