import React from 'react';
import styled from 'styled-components';

type MapInfoBlockProps = {
    children: React.ReactNode;
};

const Root = styled.div`
    position: absolute;
    top: 2.5%;
    left: 1.5%;
    padding: 1.6rem 3.2rem;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
`;

const MapInfoBlock = (props: MapInfoBlockProps): React.ReactElement => {
    const { children } = props;

    return <Root>{children}</Root>;
};

export { MapInfoBlock };
