import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@avangard/ui/utils';

import { Header } from '@modules/layout/organisms';
import { BaseLayoutProvider } from './context';

type BaseLayoutProps = {
    pageTitle: string;
    children: React.ReactNode;
    withBackIcon?: boolean;
};

const Root = styled.main`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
`;

const Main = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${breakpoints.down('sm')} {
        padding-left: 0;
    }
`;

const Container = styled.div`
    width: 100%;
    min-height: calc(100% - 120px);
    padding: 16px 24px;
    transition: 0.2s ease-in-out opacity;

    > * {
        height: 100%;
    }

    ${breakpoints.down('xs')} {
        padding: 16px;
        height: 100%;
    }
`;

const BaseLayout = (props: BaseLayoutProps): React.ReactElement => {
    const { pageTitle: pageTitleProps, children } = props;

    return (
        <BaseLayoutProvider pageTitle={pageTitleProps}>
            <Root>
                <Main>
                    <Header />

                    <Container>{children}</Container>
                </Main>
            </Root>
        </BaseLayoutProvider>
    );
};

export { BaseLayout };
