import React from 'react';
import { useQuery } from '@apollo/client';

import { ArrayHelper } from '@helpers';
import { useEntity } from '@lib/entity';
import { distributeToAlphabetGroups } from '@modules/regions/helpers';
import { RegionEntityList } from '@modules/regions/entities';
import { GetRegionsQuery, RegionUpdatedSubscription } from '@modules/regions/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetRegionsQueryType, RegionUpdatedSubscriptionType } from '@modules/types/graphql';

type UseRegionsOptions = QueryHookOptions<GetRegionsQueryType> & {
    observe?: boolean;
};

const useRegions = (options: UseRegionsOptions = {}) => {
    const { observe = true, ...queryHookOptions } = options;

    const queryResult = useQuery<GetRegionsQueryType>(GetRegionsQuery, queryHookOptions);

    const { regions, alphabetRegions, enabledRegions, count } = useEntity(() => {
        const entityList = new RegionEntityList(queryResult.data?.getRegions ?? null);

        return {
            regions: entityList.entities,
            alphabetRegions: distributeToAlphabetGroups(entityList.entities),
            enabledRegions: entityList.getEnabledRegions(),
            count: entityList.count,
        };
    }, [queryResult.data]);

    React.useLayoutEffect(() => {
        if (!observe) {
            return;
        }

        queryResult.subscribeToMore<RegionUpdatedSubscriptionType>({
            document: RegionUpdatedSubscription,
            updateQuery: (previous, options) => {
                const updatedRegion = options.subscriptionData.data?.regionUpdated;
                const currentRegions = [...(previous.getRegions ?? [])];
                const foundIdx = currentRegions.findIndex(
                    region => region?.id === updatedRegion?.id,
                );

                if (foundIdx === -1) {
                    return previous;
                }

                const updated = {
                    ...previous,
                    getRegions: ArrayHelper.replace(currentRegions, updatedRegion, foundIdx),
                } as GetRegionsQueryType;

                return updated;
            },
        });
    }, [observe]);

    const result = {
        regions,
        alphabetRegions,
        enabledRegions,
        count,
        ...queryResult,
    };

    return result;
};

export { useRegions };
