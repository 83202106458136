import styled from 'styled-components';

const RegionGroupStyled = {
    Groups: styled.div`
        columns: 5;

        & > * {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    `,

    Group: styled.div``,

    Label: styled.div`
        width: 100%;
        margin-bottom: 8px;
        color: ${p => p.theme.palette.common.black};
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3.2rem;
    `,

    List: styled.div`
        width: 100%;
        line-height: 2.2rem;
    `,
};

export { RegionGroupStyled };
